/** {
    box-sizing: border-box;
}*/

.AdViewer {
    max-width: 60em;
    margin-left: auto;
    margin-right: auto;
}

div.AdToolbar {
    /*position: fixed;*/
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    text-align: center;
    z-index: 9;
    padding: 0.5em;
    /*width: 100%;*/
    /*height: 5em;*/
    height: 3em;

    background-color: lightskyblue;
}

div.SectionHeader {
    position: -webkit-sticky;
    position: sticky;
    top: 4em;
    z-index: 9;
    background-color: lightskyblue;
    max-width: 55em;
    margin-left: auto;
    margin-right: auto;
    height: 2.5em;
}

.SectionHeaderInner {
    padding-top: 0.4em;
    padding-bottom: 0.2em;
    margin: 0.5em;
    height: 1.5em;
    border-radius: 1em;
    /*background-color: aliceblue;*/

    background-color: #7ac8fa;
    color: white;
}


.AppIcon {
    height: 3em;
    margin-right: 1em;

    cursor: pointer;
}

@media screen and (max-width: 650px) {
    .AppSloganBox {
        display: none !important;
    }
}


@media screen and (max-width: 350px) {
    .AppIcon {
        display: none !important;
    }
}



.SearchBox {
    display: inline-block;

    margin: auto;
    background-color: white;
    border-radius: 1em;
    padding: 0.5em;
    width: 60vw;
    max-width: 19em;

    vertical-align: top;
}

.SearchImage {
    height: 1.5em;
}

.SearchField {
    /*border-radius: 1em;*/
    /*border-width: 2px;*/

    height: 1em;
    margin-left: 1em;
    margin-top: 0.1em;
    border: none;
    font-size: large;
    text-align: left;
    vertical-align: top;
    width: 60%;
    resize: none;
    outline: none;
}

a {
    color: black;
}



.AdList {
    text-align: center;
    /*display: flex;*/
    /*display: grid;*/
    flex-wrap: wrap;
    vertical-align: text-top;
}

.AdItem {
    vertical-align: top;
    margin: 1em;
    display: inline-block;
    width: 8em;
    /*width: 30vw;
    height: 30vw;*/
    padding: 4px;
    background-color: rgb(255 255 255 / 0.7);
    /*border: 1px solid;
    border-color: black;*/

    border: 0.2em solid white;
    border-radius: 1em;
    flex: 20%;
    /*flex-grow: 1 1 0;*/

    text-align: center;
    align-content: center;
    cursor: pointer;
    position: relative;
}

/*@media (max-width: 600px) {
    .AdItem {
        width: 90vw;
    }
}*/

.AdItemPriceSmall {
    /*float: right;
    right: 0;*/
    background-color: white;
    /*border: "0.1em solid gray",*/
    display: inline-block;
    border-radius: 0.4em;
    margin-left: 1em;
    padding: 0.5em;
    padding-left: 0.5em;
    vertical-align: top;
    font-weight: bold;
    font-size: small;
}

.AdLocationSmall {
    text-align: center;
    font-size: small;
    /*width: 20vw;*/
}

.AdItemImageSmall {
    /*width: 20vw;
    height: 20vw;*/
    margin-top: 0.5em;
    max-width: 7em;
    max-height: 20vw;
    object-fit: cover;
    /*width: auto;
    height: auto;*/
    /*width: 100%;*/
    /*height: 100%;*/

    border-radius: 1em;
}


.AdItemText {
    display: block;
    overflow: hidden;
    color: black;
    /*background-color: white;*/
    font-weight: 500;
    /*width: 20vw;*/
}





.AdItemExpanded {
    margin: 1em;
    display: inline-block;
    width: 80vw;
    max-width: 21.2em;
    border-radius: 1em;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
    /*height: 50vw;*/


    background-color: rgb(255 255 255 / 0.8);
    border: 0.3em solid white;
    /*border: 1px solid;
    border-color: black;*/
    /*flex-grow: 1 1 0;*/
}

.AdItemCloseButton {
    position: absolute;
    right: 0;
    border-radius: 1em;
    background-color: white;
    padding: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
    cursor: pointer;
}

.AdItemTitleLarge {
    margin-bottom: 0.5em;
}

.AdItemPriceLarge {
    /*float: right;
    right: 0;*/
    background-color: white;
    /*border: "0.1em solid gray",*/
    display: inline-block;
    border-radius: 0.4em;
    margin-left: 1em;
    padding: 0.5em;
    padding-left: 0.5em;
    vertical-align: top;
    font-weight: bold;
}

.AdItemImageLarge {
    object-fit: cover;
    max-width: 20.2em;
    margin-top: 1em;
    border-radius: 1em;
    /*width: 100%;*/
    /*height: 100%;*/
}

.AdItemLocationLarge {
    margin-top: 0em;
    margin-bottom: 1em;
}

.AdItemBodyText {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    /*font-size: small;*/
}


.btnRefresh {
    vertical-align: middle;
}
