.App {
    background-color: lightskyblue;
}

.AppLogo {
    /*background-color: floralwhite;*/
    /*padding: 2em;*/
    /*width: 25vmin;*/
    width: 90vw;
    max-width: 20em;
    /*max-height: 20vmin;*/
    /*width: 90vw;*/
    /*pointer-events: none;
    align-self: start;*/
    cursor: pointer;
    border-radius: 1em;
    vertical-align: top;
}

.AppSloganBox {
    display: inline-block;
    margin: 1em;
    padding: 1em;
    background-color: aliceblue;
    border-radius: 1em;
}

.App-header {
    /*background-color: white;*/ /*#282c34; */
    /*min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;*/
    position: fixed;
    z-index: 10;
}

.App-logo {
    /*background-color: floralwhite;*/
    /*padding: 2em;*/
    /*width: 25vmin;*/
    /*height: 40vmin;*/
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        /*animation: App-logo-spin infinite 5s linear;*/
    }
}



.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
